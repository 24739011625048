import { createClient } from '@supabase/supabase-js'
import { router } from './router'
import { Database } from './types/database'

if (import.meta.env.VITE_SUPABASE_URL === undefined || import.meta.env.VITE_SUPABASE_ANON_KEY === undefined) {
  throw new Error('supabase url or anon key is missing in .env')
}

if (
  typeof import.meta.env.VITE_SUPABASE_URL === 'boolean' ||
  typeof import.meta.env.VITE_SUPABASE_ANON_KEY === 'boolean'
) {
  throw new Error('supabase url or anon key in .env is boolean but must be string')
}

const supbabaseUrl = import.meta.env.VITE_SUPABASE_URL
const supabaseAnonKey = import.meta.env.VITE_SUPABASE_ANON_KEY

export const supabase = createClient<Database>(supbabaseUrl, supabaseAnonKey)

supabase.auth.onAuthStateChange((event) => {
  if (!event.localeCompare('SIGNED_OUT')) {
    router.push({ path: '/login' })
  }
})
