import { supabase } from '@/supabase'
import { Tables } from '@/types/generatedDatabase.types'
import { User } from '@supabase/supabase-js'
import { defineStore } from 'pinia'
import { Ref, ref } from 'vue'

const DEFAULT_USER_RIGHTS: Tables<'user_rights'> = {
  id: '-1',
  created_at: new Date().toString(),
  right_salary_review: false,
  right_devices_create: false,
  right_alerting: false,
  right_device_operating_hours: false,
}

export const useAuthStore = defineStore('auth', () => {
  let user: Ref<User | null> = ref(null)
  let userRights: Ref<Tables<'user_rights'>> = ref(DEFAULT_USER_RIGHTS)

  async function fetchUserRights() {
    try {
      user.value = (await supabase.auth.getUser()!).data.user
      if (!user.value) throw new Error('USER IS NULL')
      const { data, error } = await supabase.from('user_rights').select('*').eq('id', user.value.id).single()
      if (error) {
        if (
          !(
            error.details &&
            !error.details.localeCompare('Results contain 0 rows, application/vnd.pgrst.object+json requires 1 row')
          )
        )
          throw error
      } else {
        userRights.value = data
      }
    } catch (error) {
      console.trace(error)
    }
  }
  ;(async () => {
    supabase.auth.onAuthStateChange((event, session) => {
      if (event === 'SIGNED_IN') {
        if (session) {
          user.value = session.user
          fetchUserRights()
        }
      } else if (event === 'SIGNED_OUT') {
        resetUserRightsToDefault()
      }
    })
  })()

  function resetUserRightsToDefault() {
    user.value = null
    userRights.value = DEFAULT_USER_RIGHTS
  }

  return { userRights, user, fetchUserRights, resetUserRightsToDefault }
})
