import { supabase } from '@/supabase'
import { defineStore } from 'pinia'
import { Ref, ref } from 'vue'
import { PostgrestError } from '@supabase/supabase-js'
import { Tables } from '@/types/database'
import { CustomerWithContactAndPress } from '@/types/extendDatabase'

export const useCustomerStore = defineStore('customer', () => {
  const customers: Ref<CustomerWithContactAndPress[]> = ref([])
  const contacts: Ref<Tables<'customer_contact'>[]> = ref([])

  let initDone = new Promise(async (resolve) => {
    await fetchPressCustomers()
    await fetchContacts()
    resolve(null)
  })

  async function fetchPressCustomers() {
    try {
      const { data, error } = await supabase
        .from('customer')
        .select(
          'id, label, contacts: customer_contact(id, mail, name), presses: press_customer(id, press_id, press_customer_contact(id, contact_id))'
        )
      if (error) throw error
      customers.value = data
      customers.value.push({
        id: -1,
        label: 'Nicht zugewiesen',
        contacts: [],
        presses: [],
      })
    } catch (error) {
      console.error(error)
    }
  }

  async function fetchContacts() {
    try {
      const { data, error } = await supabase.from('customer_contact').select('*')
      if (error) throw error
      contacts.value = data
    } catch (error) {
      console.error(error)
    }
  }

  async function addCustomer(label: string): Promise<PostgrestError | null> {
    try {
      const { data, error } = await supabase.from('customer').insert({ label: label }).select('*').single()
      if (error) throw error
      let newCustomerWithPress: CustomerWithContactAndPress = {
        id: data.id,
        label: data.label,
        contacts: [],
        presses: [],
      }
      customers.value.push(newCustomerWithPress)
      return null
    } catch (error: any) {
      console.error(error)
      return error
    }
  }

  async function deleteCustomer(customer_id: number): Promise<PostgrestError | null> {
    try {
      const { error: errorContact } = await supabase.from('customer_contact').delete().eq('customer_id', customer_id)
      if (errorContact) throw errorContact
      const { error: errorPress } = await supabase.from('press_customer').delete().eq('customer_id', customer_id)
      if (errorPress) throw errorPress
      const { error: errorCustomer } = await supabase.from('customer').delete().eq('id', customer_id)
      if (errorCustomer) throw errorCustomer
      let index = customers.value.findIndex((c) => c.id === customer_id)
      customers.value.splice(index, 1)
      return null
    } catch (error: any) {
      console.error(error)
      return error
    }
  }

  async function updateCustomer(customer_id: number, label: string): Promise<PostgrestError | null> {
    try {
      const updatedCustomer: Tables<'customer'> = {
        id: customer_id,
        label: label,
      }
      const { data, error } = await supabase.from('customer').update(updatedCustomer).eq('id', customer_id).single()
      if (error) throw error
      let oldCustomer = customers.value.find((c) => c.id === customer_id)!
      oldCustomer.label = label
      return null
    } catch (error: any) {
      console.error(error)
      return error
    }
  }

  async function deleteContact(customerId: number, contactId: number) {
    try {
      const { error } = await supabase.from('customer_contact').delete().eq('id', contactId)
      if (error) throw error
      let customer = customers.value.find((c) => c.id === customerId)!
      let index = customer.contacts.findIndex((c) => c.id === contactId)
      customer.contacts.splice(index, 1)
      return null
    } catch (error: any) {
      console.error(error)
      return error
    }
  }

  async function addContact(customerId: number, contact: { name: string; mail: string }) {
    try {
      const newContact = {
        customer_id: customerId,
        name: contact.name,
        mail: contact.mail,
      }
      const { data, error } = await supabase.from('customer_contact').insert(newContact).select().single()
      if (error) throw error
      let customer = customers.value.find((c) => c.id === customerId)!
      customer.contacts.push(data)
      contacts.value.push(data)
      return null
    } catch (error) {
      console.error(error)
      return error
    }
  }

  async function deletePress(customerId: number, press_id: number) {
    try {
      const { error } = await supabase.from('press_customer').delete().eq('press_id', press_id)
      if (error) throw error
      let customer = customers.value.find((c) => c.id === customerId)!
      let index = customer.presses.findIndex((c) => c.press_id === press_id)
      customer.presses.splice(index, 1)
      return null
    } catch (error: any) {
      console.error(error)
      return error
    }
  }

  async function deletePressContacts(customerId: number, pressId: number, contact_ids: number[]) {
    try {
      for (const contact_id of contact_ids) {
        let press = customers.value.find((c) => c.id === customerId)!.presses.find((p) => p.press_id === pressId)!
        let relationId = press.press_customer_contact.find((pc) => pc.contact_id === contact_id)?.id
        if (relationId === undefined) continue
        const { error } = await supabase.from('press_customer_contact').delete().eq('id', relationId)
        if (error) throw error
        let index = press.press_customer_contact.findIndex((p) => p.contact_id === contact_id)
        press.press_customer_contact.splice(index, 1)
      }
    } catch (error) {
      console.error(error)
    }
  }

  async function addPressContact(customerId: number, pressId: number, contact_id: number) {
    try {
      let press = customers.value.find((c) => c.id === customerId)!.presses.find((p) => p.press_id === pressId)!
      let newContact = {
        press_customer_id: press.id,
        contact_id: contact_id,
      }
      const { data, error } = await supabase.from('press_customer_contact').insert(newContact).select().single()
      if (error) throw error
      press.press_customer_contact.push({
        id: data.id,
        contact_id: data.contact_id,
      })
    } catch (error) {
      console.error(error)
    }
  }

  async function updatePress(customerId: number, pressId: number, contactIds: number[]) {
    let oldCustomer = getCustomerByAssetId(pressId)
    if (oldCustomer.id === customerId) {
      await deletePressContacts(
        customerId,
        pressId,
        oldCustomer.presses.find((pc) => pc.press_id === pressId)!.press_customer_contact.map((c) => c.contact_id)
      )
      for (const contact of contactIds) {
        addPressContact(customerId, pressId, contact)
      }
      return
    }
    if (oldCustomer.id != -1) {
      await deletePressContacts(
        oldCustomer.id,
        pressId,
        oldCustomer.presses.find((pc) => pc.press_id === pressId)!.press_customer_contact.map((c) => c.contact_id)
      )
      await deletePress(oldCustomer.id, pressId)
    }
    if (customerId === -1) return
    try {
      const newPress = {
        customer_id: customerId,
        press_id: pressId,
      }
      const { data, error } = await supabase.from('press_customer').insert(newPress).select().single()
      if (error) throw error
      let customer = customers.value.find((c) => c.id === customerId)!
      const supabasePress = {
        id: data.id,
        press_id: data.press_id,
        press_customer_contact: [],
      }
      customer.presses.push(supabasePress)
      for (const contact of contactIds) {
        await addPressContact(customerId, pressId, contact)
      }
      return null
    } catch (error) {
      console.error(error)
      return error
    }
  }

  async function addContactAndPressConnection(
    customerId: number,
    contact: { name: string; mail: string },
    pressList: {
      id: number
      press_id: number
      press_customer_contact: {
        id: number
        contact_id: number
      }[]
    }[]
  ) {
    try {
      const newContact = {
        customer_id: customerId,
        name: contact.name,
        mail: contact.mail,
      }
      const { data, error } = await supabase.from('customer_contact').insert(newContact).select().single()
      if (error) throw error
      let customer = customers.value.find((c) => c.id === customerId)!
      customer.contacts.push(data)
      contacts.value.push(data)
      for (const press of pressList) {
        addPressContact(customerId, press.press_id, data.id)
      }
      return null
    } catch (error) {
      console.error(error)
      return error
    }
  }

  function getAssetsByCustomerId(id: number) {
    return customers.value.find((c) => c.id === id)?.presses
  }

  function getCustomerById(id: number) {
    return customers.value.find((c) => c.id === id)
  }

  function getCustomerByAssetId(id: number) {
    for (const c of customers.value) {
      let found = c.presses.find((p) => p.press_id === id)
      if (found) return c
    }
    return { id: -1, label: '-', contacts: [], presses: [] }
  }

  function getContactsByAssetId(id: number) {
    let customer = getCustomerByAssetId(id)
    if (customer.id === -1) return []
    let contacts = customer.presses.find((p) => p.press_id === id)!.press_customer_contact
    return contacts
  }

  function getContactsByCustomerId(id: number) {
    let customer = getCustomerById(id)!
    return customer.contacts
  }

  return {
    initDone,
    customers,
    contacts,
    fetchPressCustomers,
    fetchContacts,
    addCustomer,
    deleteCustomer,
    updateCustomer,
    deleteContact,
    deletePress,
    addContact,
    addPress: updatePress,
    getAssetsByCustomerId,
    getCustomerById,
    getCustomerByAssetId,
    getContactsByAssetId,
    getContactsByCustomerId,
    addPressContact,
    addContactAndPressConnection,
  }
})
