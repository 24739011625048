//groups
// 0 - default
// 1 - LKW
// 2 - Bagger
// 3 - Radlader
// 4 - Bus
// 5 - Walze
// 10 - Dumper
// 12 - Grader
// 20 - Minibagger
// 21 - Mobilbagger
// 22-29 - Kettenbagger

import { transform } from 'ol/proj'
import { Circle, Fill, Icon, Stroke } from 'ol/style'
import Style from 'ol/style/Style'

const getStyleByGroupId = (id: number | undefined): Style => {
  return getStyleByGroupIdWithColor(id, [0, 0, 0, 1])
}

//get Icon/Style for device group (and state?)
const getStyleByGroupIdWithColor = (id: number | undefined, color: number[]): Style => {
  return new Style({
    image: new Icon({
      crossOrigin: 'anonymous',
      color,
      src: '/icons/presscontainer.svg',
      scale: 0.08,
    }),
  })
}

const getCirlceStyle = (color: number[] = [147, 197, 253, 1], radius = 5): Style => {
  const style = new Style({
    image: new Circle({
      fill: new Fill({
        color,
      }),
      stroke: new Stroke({
        color: [30, 58, 138, 1],
      }),
      radius,
    }),
  })
  return style
}

const transformToOl = (coordinates: number[]): number[] => {
  return transform(coordinates, 'EPSG:4326', 'EPSG:3857')
}

const transformFromOl = (coordinates: number[]): number[] => {
  return transform(coordinates, 'EPSG:3857', 'EPSG:4326')
}

const getArrowStyle = (direction: number) => {
  const style = new Style({
    image: new Icon({
      anchorXUnits: 'pixels',
      anchorYUnits: 'fraction',
      crossOrigin: 'anonymous',
      src: '/arrow-right-icon.svg',
      scale: 0.1,
      color: '#ff0000',
      rotation: ((direction - 90) * Math.PI) / 180,
    }),
  })
  return style
}

export { getStyleByGroupId, getStyleByGroupIdWithColor, getCirlceStyle, transformToOl, transformFromOl, getArrowStyle }
