import { supabase } from '@/supabase'

export interface DevicePicture {
  id: number
  url: string
  urlInStorage?: string
}

export interface DeviceFile {
  id: number
  name: string
  url: string
  urlInStorage?: string
}

export const getPicturesForDevice = async (id: number): Promise<DevicePicture[]> => {
  const pictures: DevicePicture[] = []
  try {
    const { data: picturesToCollect, error } = await supabase.from('g4y_device_picture').select('*').eq('device_id', id)
    if (error) throw error

    for (const picture of picturesToCollect) {
      const { error, data } = await supabase.storage.from('device-picture').download(picture.url)
      if (error) return Promise.reject(error)
      pictures.push({
        id: picture.id,
        url: URL.createObjectURL(<Blob>data),
        urlInStorage: picture.url,
      })
    }
  } catch (error) {
    return Promise.reject(error)
  }

  return pictures
}

export const getFilesForDevice = async (id: number): Promise<DeviceFile[]> => {
  const files: DeviceFile[] = []
  try {
    const { data: filesToCollect, error } = await supabase.from('g4y_device_file').select('*').eq('device_id', id)
    if (error) throw error

    for (const file of filesToCollect) {
      const { error, data } = await supabase.storage.from('device-file').download(file.url)
      if (error) return Promise.reject(error)
      files.push({
        id: file.id,
        name: file.name,
        url: URL.createObjectURL(data),
        urlInStorage: file.url,
      })
    }
  } catch (error) {
    return Promise.reject(error)
  }
  return files
}
