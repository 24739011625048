import { createRouter, createWebHashHistory, RouteRecordRaw } from 'vue-router'
import LiveTracking from '@/components/LiveTracking.vue'
import ChangePassword from '@/views/ChangePassword.vue'
import Login from '@/views/Login.vue'
import DeviceNav from '@/views/devices/Nav.vue'
import { supabase } from '@/supabase'
import { useAuthStore } from '@/store/auth'

const Replay = () => import('@/components/Replay.vue')
const SalaryReview = () => import('@/components/SalaryReview.vue')
const DeviceCreate = () => import('@/views/devices/Create.vue')
const DeviceView = () => import('@/views/devices/View.vue')
const DeviceEdit = () => import('@/views/devices/Edit.vue')
const InventoryView = () => import('@/views/inventory/View.vue')
const RightView = () => import('@/views/rights/View.vue')
const CustomerView = () => import('@/views/customer/View.vue')

const routes: RouteRecordRaw[] = [
  { path: '/', component: LiveTracking },
  { path: '/login', component: Login },
  { path: '/changePassword', component: ChangePassword },
  { path: '/replay', component: Replay },
  { path: '/replay/:deviceId', component: Replay },
  { path: '/replay/:deviceId/:from', component: Replay },
  { path: '/replay/:deviceId/:from/:to', component: Replay },
  { path: '/salaryReview', component: SalaryReview },
  { path: '/salaryReview/:deviceId', component: SalaryReview },
  { path: '/devices', component: DeviceNav },
  { path: '/devices/create', component: DeviceCreate },
  { path: '/devices/:id', component: DeviceView, props: true },
  { path: '/devices/:id/edit', component: DeviceEdit, props: true },
  { path: '/inventory', component: InventoryView, props: true },
  { path: '/rights', component: RightView },
  { path: '/customer', component: CustomerView },
]

export const router = createRouter({
  history: createWebHashHistory(),
  routes,
})

router.beforeEach(async (to, from, next) => {
  const user = (await supabase.auth.getUser())?.data.user
  if (to.fullPath.includes('access_token')) {
    const splittedPath = to.fullPath.substring(1).split('&')
    let token_type = ''
    let refreshToken = ''
    let type = ''
    for (const subPath of splittedPath) {
      if (subPath.split('=')[0] === 'refresh_token') refreshToken = subPath.split('=')[1]
      if (subPath.split('=')[0] === 'token_type') token_type = subPath.split('=')[1]
      if (subPath.split('=')[0] === 'type') type = subPath.split('=')[1]
    }
    // FIXME:
    // if (token_type == 'bearer') {
    //   await supabase.auth.signIn({ refreshToken })
    //   if (type === 'recovery') {
    //     next('/changePassword?t=recovery')
    //   } else if (type === 'invite') {
    //     next('/changePassword?t=invite')
    //   }
    // }
  } else if (user?.aud.includes('authenticated')) {
    next()
  } else if (to.fullPath.includes('login')) {
    next()
  } else {
    return next('/login')
  }
})
