import { Tables } from '@/types/generatedDatabase.types'

export const getPersonName = (person: Tables<'person'>): string => {
  if (!person.first_name && !person.last_name) return 'Ohne Namen'
  if (!person.first_name) return person.last_name!
  if (!person.last_name) return person.first_name
  return `${person.first_name} ${person.last_name}`
}

export const getCustomerLabel = (contact: Tables<'customer_contact'>) => {
  return `${contact.name} [${contact.mail}]`
}
