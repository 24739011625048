<template>
  <div class="my-1 flex h-fit w-fit space-x-2 px-2 py-1">
    <i v-if="showDelete" :class="classes" class="pi-trash text-red-600" @click="emits('delete')" />
    <i v-if="showEdit" :class="classes" class="pi-pencil" @click="emits('edit')" />
    <i v-if="showCancel" :class="classes" class="pi-times" @click="emits('cancel')" />
    <i v-if="showSave" :class="classes" class="pi-save" @click="emits('save')" />
    <i v-if="showFold && foldDirection === 'up'" :class="classes" class="pi-angle-up" @click="emits('fold')" />
    <i v-if="showFold && foldDirection === 'right'" :class="classes" class="pi-angle-right" @click="emits('fold')" />
    <i v-if="showFold && foldDirection === 'down'" :class="classes" class="pi-angle-down" @click="emits('fold')" />
    <i v-if="showFold && foldDirection === 'left'" :class="classes" class="pi-angle-left" @click="emits('fold')" />
  </div>
</template>

<script setup lang="ts">
  import { ref } from 'vue'

  const props = defineProps({
    showDelete: {
      type: Boolean,
      default: true,
    },
    showEdit: {
      type: Boolean,
      default: true,
    },
    showCancel: {
      type: Boolean,
      default: true,
    },
    showSave: {
      type: Boolean,
      default: true,
    },
    showFold: {
      type: Boolean,
      default: true,
    },
    foldDirection: {
      type: String,
      default: 'right',
      validator(value: string) {
        return ['up', 'right', 'down', 'left'].includes(value)
      },
    },
  })

  const classes = ref('pi cursor-pointer opacity-70 hover:opacity-100')

  const emits = defineEmits<{
    delete: []
    edit: []
    cancel: []
    save: []
    fold: []
  }>()
</script>

<style scoped></style>
