import { Tables } from '@/types/database'
import { supabase } from '@/supabase'
import { defineStore } from 'pinia'
import { Ref, computed, ref } from 'vue'

export const usePropertyStore = defineStore(
  'property',
  () => {
    const property_types: Ref<Tables<'device_property_type'>[]> = ref([])
    const property_type_select_values: Ref<Tables<'device_property_type_select_value'>[]> = ref([])

    async function fetchPropertyTypes() {
      try {
        const { data, error } = await supabase.from('device_property_type').select().order('id')
        if (error) throw error
        property_types.value = data
      } catch (error) {
        console.error(error)
      }
    }

    async function fetchPropertyTypeSelectValues() {
      try {
        const { data, error } = await supabase.from('device_property_type_select_value').select()
        if (error) throw error
        property_type_select_values.value = data
      } catch (error) {
        console.error(error)
      }
    }

    ;(() => {
      fetchPropertyTypes()
      fetchPropertyTypeSelectValues()
    })()

    function getPropertyTypeById(id: number): Tables<'device_property_type'> {
      return (
        property_types.value.find((t) => t.id === id) ?? {
          id,
          label: 'exisiteriert nicht',
          data_type: -1,
          decimal_places: null,
          factor: null,
          unit: null,
        }
      )
    }

    const propertyTypeById = computed(() => {
      return (id: number): Tables<'device_property_type'> => {
        return getPropertyTypeById(id)
      }
    })

    const propertyTypeLabelById = computed(() => {
      return (id: number): string => {
        return getPropertyTypeById(id).label
      }
    })

    const propertyTypeSelectOptionLabelById = computed(() => {
      return (id: number): string => {
        return property_type_select_values.value.filter((o) => o.id === id)[0].label ?? 'exisitiert nicht'
      }
    })

    const propertyTypeSelectValueByTypeId = computed(() => {
      return (id: number): Tables<'device_property_type_select_value'>[] => {
        return property_type_select_values.value
          .filter((t) => t.property_type_id === id)
          .sort((a, b) => a.label.localeCompare(b.label))
      }
    })

    const propertyTypeSelectValueByTypeAndGroupId = computed(() => {
      return (type_id: number, group_id: number | undefined): Tables<'device_property_type_select_value'>[] => {
        if (!group_id) return []
        return property_type_select_values.value
          .filter((t) => t.property_type_id === type_id && t.device_group_ids?.includes(group_id))
          .sort((a, b) => a.label.localeCompare(b.label))
      }
    })

    return {
      property_types,
      propertyTypeById,
      propertyTypeLabelById,
      property_type_select_values,
      propertyTypeSelectOptionLabelById,
      propertyTypeSelectValueByTypeId,
      propertyTypeSelectValueByTypeAndGroupId,
    }
  },
  { persist: true }
)
