<template>
  <div class="w-full lg:!w-1/2">
    <div v-if="rightsStore.isAllowed('UPDATE', 'DEVICE')">
      <label for="name" class="block text-sm font-medium text-neutral">Name</label>
      <InputText v-model="device.name" id="name" class="w-full" />
    </div>
    <div
      v-if="
        selectedBranch &&
        rightsStore.isAllowed('UPDATE', 'BRANCH') &&
        globalSettingsStore.useFeature(USE_BRANCH_OFFICES)
      "
      class="mt-5"
    >
      <label for="branch" class="block text-sm font-medium text-neutral">Niederlassung</label>
      <TreeSelect :options="branches" v-model="selectedBranch" :checkbox-mode="false" class="!w-72" id="branch" />
    </div>
    <div
      v-if="selectedGroup && rightsStore.isAllowed('UPDATE', 'DEVICE') && globalSettingsStore.useFeature(USE_GROUPS)"
      class="mt-5"
    >
      <label for="group" class="block text-sm font-medium text-neutral">Gruppe</label>
      <TreeSelect :options="groups" v-model="selectedGroup" :checkbox-mode="false" class="!w-72" id="group" />
    </div>
    <div v-if="rightsStore.isAllowed('UPDATE', 'CUSTOMER')" class="mt-5">
      <label for="customer" class="block text-sm font-medium text-neutral">Kunde</label>
      <Select
        v-model="customerSelected"
        :options="customerStore.customers"
        option-label="label"
        option-value="id"
        class="!w-72"
      />
    </div>
    <div v-if="rightsStore.isAllowed('UPDATE', 'CUSTOMER')" class="mt-5 flex flex-col">
      <label for="contact" class="block text-sm font-medium text-neutral">Vorvollmeldung an</label>
      <MultiSelect
        v-model="contactsSelected"
        :options="customerSelectedObject?.contacts"
        option-value="id"
        option-label="name"
        class="!w-72"
        empty-message=" "
        :show-toggle-all="customerSelectedObject ? customerSelectedObject.contacts.length > 0 : false"
        :max-selected-labels="1"
        selected-items-label="{} Kontakte ausgewählt"
      >
        <template #footer>
          <div class="px-4 pb-2">
            <p v-if="customerSelected == -1">Bitte zuerst Kunden zuweisen</p>
            <p v-else-if="customerSelectedObject!.contacts.length == 0">
              Bitte erst Kontakt über <br />Kundenverwaltung anlegen
            </p>
          </div>
        </template>
      </MultiSelect>
      <small v-if="selectedBranchObject" class="flex"> Standardmäßig Mail an: {{ selectedBranchObject.mail }} </small>
    </div>
    <!-- <div class="mt-5 flex">
      <label for="active" class="!text-base">Anzeige in Anwendung</label>
      <input v-model="device.active" id="active" type="checkbox" class="ml-3" />
    </div> -->
    <!-- <div class="mt-5 flex" v-if="userRights.right_alerting">
      <label
        for="alerting"
        class="!text-base"
        v-tooltip="'Bei Nutzung außerhalb der erlaubten Nutzungszeit wird eine Alarmmeldung verschickt.'"
        >Alarmierung</label
      >
      <input v-model="device.alerting" id="alerting" type="checkbox" class="ml-3" />
    </div> -->
    <DevicePictureGallery
      v-if="rightsStore.isAllowed('UPDATE', 'DEVICE')"
      :deviceId="device.id.toString()"
      :edit="true"
      class="mt-8"
    />
    <DeviceFileGallery v-if="rightsStore.isAllowed('UPDATE', 'DEVICE')" :deviceId="device.id.toString()" :edit="true" />
  </div>
  <div class="w-full lg:!w-1/2">
    <div
      v-if="rightsStore.isAllowed('UPDATE', 'OPERATINGHOURS')"
      class="mt-2 w-full sm:w-1/2"
      @click="popupOperatingHours.toggle"
    >
      <span>Betriebsstunden:</span>
      {{ device.operating_hours ? device.operating_hours.toLocaleString('de-de') : 0 }}h
      <button
        class="cursor-pointer text-sm font-light text-gray-700 hover:text-secondary"
        v-if="useAuthStore().userRights.right_device_operating_hours"
      >
        (bearbeiten)
      </button>
      <Popover ref="popupOperatingHours" showCloseIcon dismissable>
        <OperatingHoursAdmin :deviceId="device.id" class="!bg-white !p-2" />
      </Popover>
    </div>
    <div v-if="device.group_id && rightsStore.isAllowed('UPDATE', 'DEVICE')">
      <PropertyEdit
        v-for="supported_property in supported_properties"
        :property_type="propertyStore.propertyTypeById(supported_property.property_type_id)"
        :select-options="
          propertyStore.propertyTypeSelectValueByTypeAndGroupId(supported_property.property_type_id, device.group_id)
        "
        class="mt-5 first:mt-8 lg:first:mt-0"
        v-model="
          device.properties[getIndex(supported_property.property_type_id)][
            getDataTypeLabel(supported_property.property_type_id) ?? 'value_text'
          ]
        "
      />
    </div>
  </div>
</template>

<script setup lang="ts">
  import { useDeviceGroupStore } from '@/store/deviceGroup'
  import { usePropertyStore } from '@/store/property'
  import { DeviceWithProperties } from '@/types/extendDatabase'
  import { cloneDeep } from 'lodash'
  import { computed, reactive, Ref, ref, watch } from 'vue'
  import { getDataTypeLabel } from '@/helper/property'
  import PropertyEdit from '@/components/PropertyEdit.vue'
  import { useAuthStore } from '@/store/auth'
  import DevicePictureGallery from '@/components/DevicePictureGallery.vue'
  import { supabase } from '@/supabase'
  import { useDeviceStore } from '@/store/device'
  import OperatingHoursAdmin from '@/components/OperatingHoursAdmin.vue'
  import { useCustomerStore } from '@/store/customer'
  import { useRightsStore } from '@/store/rights'
  import { useBranchOfficeStore } from '@/store/branchOffice'
  import DeviceFileGallery from '../utilityComponents/DeviceFileGallery.vue'
  import { USE_BRANCH_OFFICES, USE_GROUPS, useGlobalSettingsStore } from '@/store/globalSettings'

  const groupStore = useDeviceGroupStore()
  const propertyStore = usePropertyStore()
  const deviceStore = useDeviceStore()
  const customerStore = useCustomerStore()
  const rightsStore = useRightsStore()
  const branchOfficeStore = useBranchOfficeStore()
  const globalSettingsStore = useGlobalSettingsStore()
  const userRights = computed(() => useAuthStore().userRights)
  let isSaving = ref(false)
  const popupOperatingHours = ref()

  const props = defineProps<{
    device: DeviceWithProperties
  }>()

  const device: DeviceWithProperties = reactive(cloneDeep(props.device))

  const groups = computed(() => groupStore.groupsAsTree)
  const currentGroup = computed(() => groupStore.groupById(device.group_id ?? undefined))
  const selectedGroup: Ref = ref(initTreeNode(currentGroup.value?.id))
  watch(currentGroup, () => (selectedGroup.value = initTreeNode(currentGroup.value?.id)))

  const branches = computed(() => branchOfficeStore.branchOfficesAsTree)
  const currentBranch = computed(() => branchOfficeStore.getBranchById(device.branch_office_id ?? undefined))
  const selectedBranch: Ref = ref(initTreeNode(currentBranch.value?.id))
  watch(currentBranch, () => (selectedBranch.value = initTreeNode(currentBranch.value?.id)))
  const selectedBranchObject = computed(() =>
    Object.keys(selectedBranch.value)[0] != '-1'
      ? branchOfficeStore.getBranchById(parseInt(Object.keys(selectedBranch.value)[0]))
      : undefined
  )

  const ids = computed(() => {
    const branch = Object.keys(selectedBranch.value)[0] != '-1' ? parseInt(Object.keys(selectedBranch.value)[0]) : null
    const group = Object.keys(selectedGroup.value)[0] != '-1' ? parseInt(Object.keys(selectedGroup.value)[0]) : null
    return { branch: branch, group: group }
  })

  function initTreeNode(id: number | undefined) {
    if (id === undefined) return { '-1': true }
    const obj = {}
    //@ts-ignore
    obj[id.toString()] = true
    return obj
  }

  const supported_properties = computed(() => {
    if (!currentGroup.value) return []
    return currentGroup.value.supported_properties.sort(
      (a, b) => (a.on_detail_page_position_row ?? 0) - (b.on_detail_page_position_row ?? 0)
    )
  })

  watch(
    supported_properties,
    (_newValue) => {
      for (const supported_property of supported_properties.value) {
        const index = device.properties.findIndex((p) => p.property_type_id === supported_property.property_type_id)
        if (index === -1) {
          device.properties.push({
            device_id: device.id,
            property_type_id: supported_property.property_type_id,
          })
        }
      }
    },
    { immediate: true }
  )

  const getIndex = (property_type_id: number): number => {
    return device.properties.findIndex((p) => p.property_type_id === property_type_id)
  }

  const save = async () => {
    if (isSaving.value) return
    isSaving.value = true
    const properties = device.properties.filter((p) => {
      let remove = true
      Object.keys(p).map((key) => {
        if (key.startsWith('value_')) {
          if (p[<keyof DeviceProperty>key] != undefined) remove = false
        }
      })
      return !remove
    })

    const promises: Promise<void>[] = []

    properties.map(async (property) => {
      let promise = new Promise<void>(async (resolve, reject) => {
        if (property[getDataTypeLabel(property.property_type_id)] != undefined) {
          const { error } = await supabase.from('device_property').upsert(property)
          if (error) reject(error)
          resolve()
        }
        resolve()
      })
      promises.push(promise)
    })

    promises.push(
      new Promise<void>(async (resolve, reject) => {
        const { error } = await supabase
          .from('g4y_device')
          .update(
            {
              name: device.name,
              group_id: ids.value.group,
              branch_office_id: ids.value.branch,
              active: device.active,
              alerting: device.alerting,
            },
            { returning: 'minimal' }
          )
          .eq('id', device.id)
        if (error) reject(error)
        resolve()
      })
    )

    customerStore.addPress(customerSelected.value, device.id, contactsSelected.value)

    const allPromises = Promise.all(promises)
    return new Promise((resolve, reject) => {
      allPromises
        .then(() => {
          deviceStore.updateDeviceFromServerById(device.id)
          resolve({ message: 'Erfolgreich gespeichert' })
        })
        .catch((error) => {
          console.error(error)
          reject({ error: 'Fehler beim Speichern' })
        })
        .finally(() => {
          isSaving.value = false
        })
    })
  }

  defineExpose({
    save,
  })

  await customerStore.initDone
  const customerSelected = ref(customerStore.getCustomerByAssetId(device.id).id)
  const customerSelectedObject = computed(() => customerStore.customers.find((c) => c.id === customerSelected.value))
  const contactsSelected = ref(customerStore.getContactsByAssetId(device.id).map((c) => c.contact_id))
  watch(customerSelected, () => {
    if (customerSelected.value === customerStore.getCustomerByAssetId(device.id).id) {
      contactsSelected.value = customerSelectedObject
        .value!.press_customer.find((p) => p.press_id === device.id)!
        .press_customer_contact.map((pc) => pc.contact_id)
    } else contactsSelected.value = []
  })
</script>
