import { BLE_TYPE, GPS_TYPE } from '@/helper/filterAndSorting'
import { supabase } from '@/supabase'
import { defineStore } from 'pinia'
import { TreeNode } from 'primevue/treenode'
import { Ref, computed, ref } from 'vue'
import { useRightsStore } from './rights'
import { useAuthStore } from './auth'
import { GroupWithSupportedProperties } from '@/types/extendDatabase'

export const useDeviceGroupStore = defineStore('deviceGroup', () => {
  const groups: Ref<GroupWithSupportedProperties[]> = ref([])
  const groupMap: Ref<Map<number, GroupWithSupportedProperties>> = ref(new Map())
  const groupsAsTree: Ref<TreeNode[]> = ref([])
  const rightsStore = useRightsStore()
  const authStore = useAuthStore()

  async function fetchDeviceGroups() {
    try {
      const { data, error } = await supabase
        .from('g4y_device_group')
        .select('*, supported_properties:device_group_device_property_type(*)')
      if (error) throw error
      groups.value = data
      groupsAsTree.value = buildTree(data)
    } catch (error) {
      console.error(error)
    }
  }

  function buildTree(list: GroupWithSupportedProperties[]): TreeNode[] {
    groupMap.value = new Map()
    for (const item of list) {
      groupMap.value.set(item.id, item)
    }
    const right = rightsStore.rights.find((r) => r.user_id === authStore.user?.id)
    if (!right) return []
    const init = right.assigned_groups.map((g) => groupMap.value.get(g)).filter((b) => b != undefined)
    return init.map((g) => {
      return {
        label: g.label ?? 'Ohne Namen',
        key: g.id.toString(),
        children: genChild(g.child_ids),
        data: init,
      }
    })
  }

  function genChild(childs: number[]): TreeNode[] {
    if (childs.length === 0) return []
    const nodes: TreeNode[] = []
    for (const childId of childs) {
      let child = groupMap.value.get(childId)!
      nodes.push({
        label: child.label ?? 'Ohne Namen',
        key: child.id.toString(),
        children: genChild(child.child_ids),
        data: child,
      })
    }
    return nodes
  }

  ;(async () => {
    await rightsStore.initDone
    fetchDeviceGroups()
  })()

  const groupById = computed(() => {
    return (id: number | undefined): GroupWithSupportedProperties | undefined => {
      return id !== undefined ? groupMap.value.get(id) ?? undefined : undefined
    }
  })

  const isBleType = computed(() => {
    return (groupId: number | undefined) => {
      return groups.value.find((g) => g.id === groupId)?.type === BLE_TYPE
    }
  })

  const isGPSType = computed(() => {
    return (groupId: number | undefined) => {
      return groups.value.find((g) => g.id === groupId)?.type === GPS_TYPE
    }
  })

  function initGroupsByRights(nodeIds: number[]): TreeNode[] {
    if (groupMap.value.size === undefined) return [{ key: '-1' }]
    const nodes = nodeIds.map((b) => groupMap.value.get(b)).filter((b) => b != undefined)
    return nodes.map((b) => {
      return {
        label: b.label ?? 'Ohne Namen',
        key: b.id.toString(),
        children: genChild(b.child_ids),
        data: b,
      }
    })
  }

  function getTree() {
    return groupsAsTree.value
  }

  return {
    groups,
    groupById,
    isBleType,
    isGPSType,
    groupMap,
    groupsAsTree,
    initGroupsByRights,
    getTree,
    fetchDeviceGroups,
  }
})
