import { supabase } from '@/supabase'
import { Tables } from '@/types/database'
import { defineStore } from 'pinia'
import { Ref, ref } from 'vue'

export const USE_BRANCH_OFFICES = 'useBranchOffices'
export const USE_GROUPS = 'useGroups'
export const USE_OPERATING_HOURS = 'useOperatingHours'
export const USE_UTILIZATION_CHART = 'useUtilizationChart'
export const USE_MAINTENANCE = 'useMaintenance'
export const USE_RIGHTS = 'useRights'
export const USE_CUSTOMER = 'useCustomer'

const DEFAULT_GLOBAL_SETTINGS: Tables<'settings_global'>[] = [
  { id: 1, identifier_text: 'useBranchOffices', use: false },
  { id: 3, identifier_text: 'useGroups', use: false },
  { id: 4, identifier_text: 'useOperatingHours', use: false },
  { id: 5, identifier_text: 'useUtilizationChart', use: false },
  { id: 6, identifier_text: 'useMaintenance', use: false },
  { id: 7, identifier_text: 'useRights', use: false },
  { id: 8, identifier_text: 'useCustomer', use: false },
]

export const useGlobalSettingsStore = defineStore('globalSettings', () => {
  let globalSettings: Ref<Tables<'settings_global'>[]> = ref(DEFAULT_GLOBAL_SETTINGS)

  async function fetchGlobalSettings() {
    try {
      const { data, error } = await supabase.from('settings_global').select('*')
      if (error) throw error
      for (const entry of data) {
        let index = globalSettings.value.findIndex((s) => s.identifier_text === entry.identifier_text)
        if (index === -1) continue
        globalSettings.value[index].use = entry.use
      }
    } catch (error) {
      console.error(error)
    }
  }
  ;(() => {
    fetchGlobalSettings()
  })()

  function useFeature(feature: string): boolean {
    return globalSettings.value.find((g) => g.identifier_text === feature)?.use ?? false
  }

  return { globalSettings, fetchGlobalSettings, useFeature }
})
