<template>
  <div class="mb-2">
    ab <DatePicker v-model="fromDate" :maxDate="new Date()" showIcon style="height: 2rem; width: 8rem" />
  </div>

  <ProgressBar mode="indeterminate" style="height: 6px" class="mb-6 mt-6" v-if="loading"></ProgressBar>
  <Bar v-else :options="options" :data="{ labels, datasets }" />
</template>

<script setup lang="ts">
  import { supabase } from '@/supabase'
  import { Chart as ChartJS, Title, Tooltip, Legend, BarElement, CategoryScale, LinearScale } from 'chart.js'
  import { Bar } from 'vue-chartjs'
  import { Ref, computed, ref, watch } from 'vue'
  import { formatDateYYYYMMDD } from '@/helper/date'

  ChartJS.register(Title, Tooltip, Legend, BarElement, CategoryScale, LinearScale)

  const props = defineProps({
    deviceId: {
      type: Number,
      required: true,
    },
    showIgnition: {
      type: Boolean,
      default: true,
    },
    showIgnitionAndMovement: {
      type: Boolean,
      default: true,
    },
  })

  const today = new Date()
  const fromDate = ref(new Date())
  fromDate.value.setDate(today.getDate() - 6)

  const loading = ref(true)

  const ignitionData: Ref<number[]> = ref([])
  const ignitionAndMovementData: Ref<number[]> = ref([])

  const datasets = computed(() => {
    const datasets = []
    if (props.showIgnitionAndMovement) {
      datasets.push({
        label: 'Zündung und Bewegung',
        backgroundColor: 'rgb(104, 211, 159)',
        borderColor: 'rgb(104, 211, 159)',
        data: ignitionAndMovementData.value,
      })
    }
    if (props.showIgnition) {
      datasets.push({
        label: 'Zündung',
        backgroundColor: 'rgb(246, 224, 94)',
        borderColor: 'rgb(255, 99, 132)',
        data: ignitionData.value,
      })
    }
    return datasets
  })

  let labels: string[] = []

  const options = computed(() => {
    return {
      scales: {
        x: { stacked: true },
        y: { suggestedMax: 8, title: { display: true, text: 'Stunden' }, ticks: { stepSize: 2 } },
      },
      locale: 'de-DE',
    }
  })

  const load = async () => {
    loading.value = true

    const toDate = new Date()
    toDate.setTime(fromDate.value.getTime() + 6 * 24 * 60 * 60 * 1000)
    const from = formatDateYYYYMMDD(fromDate.value)
    const to = formatDateYYYYMMDD(toDate)

    const { data, error } = await supabase
      .from('g4y_device_utilization')
      .select('*')
      .eq('device_id', props.deviceId)
      .gte('date', from)
      .lte('date', to)
      .order('date')

    if (error) throw error
    labels = []
    const dataIgnitionAndMovement: number[] = []
    const dataIgnition: number[] = []

    const weekday = ['So', 'Mo', 'Di', 'Mi', 'Do', 'Fr', 'Sa']

    for (let u of data) {
      labels.push(weekday[new Date(u.date!).getDay()])
      dataIgnitionAndMovement.push(Math.round(((u.ignition_and_movement ?? 0) / 60 / 60) * 10) / 10)
      dataIgnition.push(Math.round(((u.ignition ?? 0) / 60 / 60) * 10) / 10)
    }

    ignitionData.value = dataIgnition
    ignitionAndMovementData.value = dataIgnitionAndMovement

    loading.value = false
  }

  load()

  watch(fromDate, async () => {
    await load()
  })
</script>
