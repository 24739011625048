import { TreeNode } from 'primevue/treenode'

export function initNodes(tree: TreeNode[]) {
  const nodes = {}
  for (const node of flatKeys(tree)) {
    //@ts-ignore
    nodes[node] = {
      checked: true,
      partialChecked: false,
    }
  }
  return nodes
}

export function flatKeys(nodes: TreeNode[]): string[] {
  return nodes.flatMap((n) => {
    return n.children ? flatKeys(n.children).concat([n.key!]) : [n.key!]
  })
}

export function reduceNodesStart(tree: TreeNode[], nodes: {}): string[] {
  const checkedNodes = getCheckedNodes(nodes)
  return tree.flatMap((b) => reduceNodes(b, checkedNodes))
}

function reduceNodes(node: TreeNode, filteredNodes: string[]): string[] {
  if (filteredNodes.includes(node.key!)) return [node.key!]
  if (node.children) return node.children.flatMap((c) => reduceNodes(c, filteredNodes))
  return []
}

export function getCheckedNodes(nodes: {}) {
  //@ts-ignore
  return Object.keys(nodes).filter((k) => nodes[k].checked)
}
