<template>
  <div :class="{ 'flex flex-row': dataType === 'BOOLEAN' }">
    <label class="block text-sm font-medium text-neutral" :for="id">
      {{ property_type.label }} <span v-if="property_type.unit">[{{ property_type.unit }}]</span></label
    >
    <input
      type="date"
      v-if="dataType === 'DATE'"
      class="p-calendar p-inputtext p-component"
      :input-id="id"
      :show-icon="true"
      :value="modelValue"
      @update:model-value="$emit('update:modelValue', $event)"
    />
    <DatePicker
      v-else-if="dataType === 'SERVICE_DATE'"
      view="month"
      dateFormat="mm/yy"
      :model-value="modelValue ? toDate(<string>modelValue) : null"
      @update:model-value="
        (value) => {
          if (value) $emit('update:modelValue', `${(<Date>value).getFullYear()}-${(<Date>value).getMonth() + 1}-1`)
        }
      "
      @input="emitMonthYearDateUpdate(($event.target as HTMLInputElement)?.value)"
    />
    <Select
      v-else-if="dataType === 'SELECT'"
      :id="id"
      :modelValue="<string>modelValue"
      :options="selectOptions"
      optionLabel="label"
      optionValue="id"
      @update:model-value="$emit('update:modelValue', $event)"
      class="w-full"
    />
    <Textarea
      v-else-if="dataType === 'TEXT'"
      :id="id"
      :modelValue="<string>modelValue"
      @update:model-value="$emit('update:modelValue', $event)"
      rows="5"
      class="w-full"
    />
    <InputText
      v-else-if="dataType === 'STRING'"
      :id="id"
      :modelValue="<string>modelValue"
      @update:model-value="$emit('update:modelValue', $event)"
      class="w-full"
    />
    <!-- TODO INTEGER locale global -->

    <InputNumber
      v-else-if="dataType === 'INTEGER'"
      :id="id"
      :modelValue="((<number>modelValue?? 0)/(property_type.factor ?? 1))"
      @update:model-value="$emit('update:modelValue', <number>(<unknown>$event) * (property_type.factor ?? 1))"
      mode="decimal"
      :minFractionDigits="property_type.decimal_places ?? 0"
      :maxFractionDigits="property_type.decimal_places ?? 0"
      class="w-full"
      locale="de-DE"
    />
    <!-- TODO FLOAT ... + locale global -->
    <InputNumber
      v-else-if="dataType === 'FLOAT'"
      :id="id"
      :modelValue="<number>modelValue"
      @update:model-value="$emit('update:modelValue', $event)"
      mode="decimal"
      :minFractionDigits="2"
      class="w-full"
      locale="de-DE"
    />
    <Checkbox
      v-else-if="dataType === 'BOOLEAN'"
      :id="id"
      :modelValue="<string>modelValue"
      @update:model-value="$emit('update:modelValue', $event)"
      :binary="true"
      class="ml-2"
    />
    <div v-else>
      Datentyp nicht unterstützt:
      {{ dataType }}
    </div>
  </div>
</template>

<script setup lang="ts">
  import { propertyTypeDataTypes } from '@/consts'
  import { Tables } from '@/types/generatedDatabase.types'
  import { computed } from 'vue'

  const props = defineProps({
    property_type: {
      type: Object as () => Tables<'device_property_type'>,
      required: true,
    },
    modelValue: {
      required: true,
    },
    selectOptions: {
      type: Array as () => Tables<'device_property_type_select_value'>[],
      required: false,
    },
  })
  const emit = defineEmits(['update:modelValue'])

  const id = `property-${props.property_type.label}-${props.property_type.id}`
  const dataType = computed(() => propertyTypeDataTypes[props.property_type.data_type])

  const emitMonthYearDateUpdate = (dateString: String) => {
    const length = dateString.length
    if (length === 7 && dateString.substring(2, 3) === '/') {
      emit('update:modelValue', `${dateString.substring(3, 7)}-${dateString.substring(0, 2)}-01`)
    } else if (length === 6 && dateString.substring(1, 2) === '/') {
      emit('update:modelValue', `${dateString.substring(2, 6)}-${dateString.substring(0, 1)}-1`)
    }
  }

  const toDate = (dateString: string) => {
    const dateArray = dateString.split('-')
    return new Date(parseInt(dateArray[0]), parseInt(dateArray[1]) - 1)
  }
</script>
