import { IGNITION_OFF, IGNITION_ON, IGNITION_ON_AND_MOVEMENT } from '@/consts'
import { DeviceWithPropertiesRaw, DeviceWithProperties } from '@/types/extendDatabase'

function getDeviceState(ignition: boolean, movement: boolean): number {
  return !ignition ? IGNITION_OFF : !movement ? IGNITION_ON : IGNITION_ON_AND_MOVEMENT
}

export const prepareDevice = (device_in: DeviceWithPropertiesRaw): DeviceWithProperties => {
  const device: DeviceWithProperties = {
    ...device_in,
    name: device_in.name ?? 'Ohne Namen',
    position: device_in.position ?? { type: 'Point', coordinates: [0, 0] },
    updated: device_in.updated != undefined ? new Date(device_in.updated + '+00:00') : new Date('1970-01-01'),
    state: getDeviceState(device_in.ignition ?? false, device_in.movement ?? false),
  }
  return device
}
