<template>
  <TreeSelect
    v-model="selectedNodes"
    :options="availableNodes"
    selection-mode="checkbox"
    class="w-full"
    :placeholder="placeHolder"
  />
</template>
<script lang="ts"></script>

<script setup lang="ts">
  import { getCheckedNodes, initNodes } from '@/helper/treeHelper'
  import { useBranchOfficeStore } from '@/store/branchOffice'
  import { useDeviceGroupStore } from '@/store/deviceGroup'
  import { TreeNode } from 'primevue/treenode'
  import TreeSelect from 'primevue/treeselect'
  import { computed, Ref, ref, watch } from 'vue'

  const props = defineProps({
    type: {
      type: String,
      default: 'branch',
    },
  })
  const placeHolder = ref('LADEN...')
  switch (props.type) {
    case 'branch':
      placeHolder.value = 'Niederlassung wählen'
      break
    case 'group':
      placeHolder.value = 'Gruppe wählen'
      break
    default:
      placeHolder.value = 'Unbekannter Typ'
  }

  const store = props.type === 'branch' ? useBranchOfficeStore() : useDeviceGroupStore()

  //INTERNAL Representation
  const availableNodes: Ref<TreeNode[]> = computed(() => store.getTree())
  const selectedNodes = ref()

  selectedNodes.value = initNodes(availableNodes.value)
  watch(store, () => (selectedNodes.value = initNodes(availableNodes.value)))

  //External Representation
  const modelValue = defineModel({ required: true })
  modelValue.value = getCheckedNodes(selectedNodes!.value).map((k) => parseInt(k))
  watch(selectedNodes, () => (modelValue.value = getCheckedNodes(selectedNodes.value).map((k) => parseInt(k))))
</script>

<style scoped></style>
